import React from "react"

import { Box, Container, Flex, Text } from "components"

const Gallery = ({ content, hideAlt = false }) => (
  <Box key={content.id} my={[4, 5, 6]}>
    <Container>
      <Flex flexWrap="wrap" justifyContent="center" m={-3}>
        {content.images.map((image, index) => (
          <Box
            width={["100%", 1 / 2]}
            p={3}
            key={content.id + "gallery" + index}
          >
            <img
              src={image.url}
              alt={image.alt}
              width={"100%"}
              height={"auto"}
            />
            {!hideAlt && <Text children={image.alt} mt={2} fontWeight={600} />}
          </Box>
        ))}
      </Flex>
    </Container>
  </Box>
)

export default Gallery
